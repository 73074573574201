import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <>
      <nav className="navbar">
        <div className="nav-container ">
         {/* <div className="nav-logo">
         <Typewriter
                options={{
                  strings: ["Admission", "Summer-2022"],
                  autoStart: true,
                  loop: true,
                }}
              />
         </div> */}
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/admission-procedure"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Admission Procedure
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/admission-fee"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Tution Fee
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/waiver"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Waiver
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/online-form"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Admission Form
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/pay-now"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Pay Now
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                About Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;