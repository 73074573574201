import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderBar from "./components/HeaderBar";
import routes from "./routes";
import React, { Suspense } from "react";
import loadingums from "./images/loading.gif";
import ScrollToTop from "react-scroll-to-top";

function App() {
  const loading = (
    <div className="container text-center">
      <img
        src={loadingums}
        style={{ height: "200px", width: "200px", marginTop: "50px" }}
      />
    </div>
  );

  return (
    <div className="mainbody">
    
      <ScrollToTop
        smooth
        style={{ backgroundColor: "hotpink" }}
        component={
          <p style={{ color: "blue", fontWeight: "bolder" }}>
            <i className="fa fa-arrow-up" aria-hidden="true"></i>
          </p>
        }
      />
      <Router>
        <Suspense fallback={loading}>
          <HeaderBar />
        </Suspense>
        <Suspense fallback={loading}>
          <Navbar />
        </Suspense>
        <Suspense fallback={loading}>
          <Switch>
            {/* <Route path='/' exact component={Home} />
          <Route path='/services' exact component={Services} />
          <Route path='/products' exact component={Products} /> */}
            {routes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => <route.component {...props} />}
                  />
                )
              );
            })}
          </Switch>
        </Suspense>
        <Suspense fallback={loading}>
          <Footer />
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
