import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import Typewriter from "typewriter-effect";
import paulogo from "../images/icon.png";
function Footer() {
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  return (
    <div className="footer-container" >
      {/* <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/sign-up'>How it works</Link>
            <Link to='/'>Testimonials</Link>
            <Link to='/'>Careers</Link>
            <Link to='/'>Investors</Link>
            <Link to='/'>Terms of Service</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/'>Contact</Link>
            <Link to='/'>Support</Link>
            <Link to='/'>Destinations</Link>
            <Link to='/'>Sponsorships</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Videos</h2>
            <Link to='/'>Submit Video</Link>
            <Link to='/'>Ambassadors</Link>
            <Link to='/'>Agency</Link>
            <Link to='/'>Influencer</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='/'>Instagram</Link>
            <Link to='/'>Facebook</Link>
            <Link to='/'>Youtube</Link>
            <Link to='/'>Twitter</Link>
          </div>
        </div>
      </div> */}
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              <Typewriter
                options={{
                  strings: ["Primeasia University", "Thanks for visiting"],
                  autoStart: true,
                  loop: true,
                }}
              />
              
            </Link>
          </div>
         
          
          <p className="website-rights">&#169; Copyright Primeasia University UMS {currentYear} </p>
        </div>
        <div className="social-icons" style={{margin:"auto"}}>
        <a
              className="social-icon-link twitter"
              href="https://primeasia.edu.bd/"
              target="_blank"
              rel="noreferrer"
              aria-label="Official Website"
              style={{textDecoration:'none',color:'white', borderRadius:"20px", padding:"5px",margin:'5px',marginLeft:"10px",boxShadow:"1px 1px 1px 1px #C5C6D0",textAlign:'center'}}
            >
              <img src={paulogo} style={{height:"40px",width:"50px" }} alt="paulogo"/>
              Website
            </a>
            <a
              className="social-icon-link facebook"
              href="https://www.facebook.com/pau.edu.bd/"
              target="_blank"
              rel="noreferrer"
              aria-label="Facebook"
              style={{textDecoration:'none',color:'white', borderRadius:"20px", padding:"5px",margin:'5px',marginLeft:"10px",boxShadow:"1px 1px 1px 1px #C5C6D0",textAlign:'center'}}
            >
              <i className="fab fa-facebook-f" /> Facebook
            </a>
           
            <a
              className="social-icon-link youtube"
              href="https://www.youtube.com/PrimeasiaUniversityOfficialYoutube"
              target="_blank"
              rel="noreferrer"
              aria-label="Youtube"
              style={{textDecoration:'none',color:'white', borderRadius:"20px", padding:"5px",margin:'5px',marginLeft:"10px",boxShadow:"1px 1px 1px 1px #C5C6D0",textAlign:'center'}}
            >
              <i className="fab fa-youtube" /> Youtube
            </a>
            <a
              className="social-icon-link twitter"
              href="https://twitter.com/bdprimeasia"
              target="_blank"
              rel="noreferrer"
              aria-label="Twitter"
              style={{textDecoration:'none',color:'white', borderRadius:"20px", padding:"5px",margin:'5px',marginLeft:"10px",boxShadow:"1px 1px 1px 1px #C5C6D0",textAlign:'center'}}
            >
              <i className="fab fa-twitter" />Twitter
            </a>
            
          </div>
      </section>
    </div>
  );
}

export default Footer;
