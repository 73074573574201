import React from 'react';
const Home = React.lazy(() => import('./components/pages/Home'));
const Services = React.lazy(() => import('./components/pages/Services'));
const Products = React.lazy(() => import('./components/pages/Products'));
const Waiver = React.lazy(() => import('./components/pages/Waiver'));
const OnlineForm = React.lazy(() => import('./components/pages/OnlineForm'));
const PayNow = React.lazy(() => import('./components/pages/Paynow'));
const Complete = React.lazy(() => import('./components/pages/Complete'));
const About = React.lazy(() => import('./components/pages/AboutUs'));
const Contact = React.lazy(() => import('./components/pages/ContactUs'));
const routes = [
    { path: '/', exact: true, name: 'Home', component: Home },
    { path: '/admission-procedure', name: 'Admission Procedure', component: Services },
    { path: '/admission-fee', name: 'Admission Fee', component: Products },
    { path: '/waiver', name: 'Admission Fee', component: Waiver },
    { path: '/online-form', name: 'Online Form', component: OnlineForm },
    { path: '/pay-now', name: 'Pay Now', component: PayNow },
    { path: '/complete', name: 'Pay Now', component: Complete },
    { path: '/about', name: 'About-us', component: About },
    { path: '/contact', name: 'Contact-us', component: Contact }
]

export default routes;