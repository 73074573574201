import React from 'react'
// import logo from '../images/Webp converted/zip_session_c759cc81ad51e2e8e35585537536cb75/Asset_5.webp';
import logo from '../images/primeasia.gif';
import './HeaderBar.css';
function HeaderBar() {
    return (
        <div className='head-bar' style={{textAlign:"center"}}>
           <img src={logo}   alt="logo"/>
           <h2 >Online Admission</h2>
                
        </div>
    )
}

export default HeaderBar
